<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-4 px-2>
      <v-flex xs12>
        <v-layout pt-3 wrap justify-start>
          <v-flex xs7 sm3 md3 lg2 xl2>
            <v-layout wrap justify-center>
              <v-flex xs3 sm2 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="15px"
                  alt="STYLOOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm10 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                  "
                >
                  Order Reports
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm3 md2 lg2 xl2 pa-1 style="font-family: poppinsregular">
            <v-select
              v-model="day"
              :items="days"
              item-text="days"
              item-value="days"
              outlined
              dense
              @change="dateFilter(day)"
              label="Date"
            ></v-select>
          </v-flex>
          <v-flex
            xs12
            sm3
            md2
            text-right
            pa-1
            v-if="day == 'Custom'"
            style="font-family: poppinsregular"
          >
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="getData(), (menu1 = false)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex
            xs12
            sm3
            md2
            text-right
            pa-1
            v-if="day == 'Custom'"
            style="font-family: poppinsregular"
          >
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="getData(), (menu2 = false)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm3 md2 pa-1 style="font-family: poppinsregular">
            <v-select
              dense
              :items="items"
              v-model="orderStatus"
              label="Order Status"
              outlined
              item-text="name"
              item-value="value"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm6 md1 lg1 xl1 pa-1>
            <v-btn color="#30B868">
              <a
                target="_blank"
                :href="
                  ipURL + '/order/summary/pdf?fromDate=' +
                  fromDate +
                  '&toDate=' +
                  toDate +
                  '&sellerId=' +
                  appUser.sellerId._id +
                  '&orderStatus=' +
                  orderStatus
                "
                class="downlink"
              >
                <span
                  style="
                    font-size: 12px;
                    font-family: poppinssemibold;
                    color: #fff;
                  "
                >
                <v-icon>mdi-download-outline</v-icon> PDF</span
                >
              </a>
            </v-btn>
          </v-flex>
          <v-flex xs12 sm6 md1 lg1 pa-1>
            <v-btn color="#30B868">
              <a
                target="_blank"
                :href="
                  ipURL + '/order/summary/excel?fromDate=' +
                  fromDate +
                  '&toDate=' +
                  toDate +
                  '&sellerId=' +
                  appUser.sellerId._id +
                  '&orderStatus=' +
                  orderStatus
                "
                class="downlink"
              >
                <span
                  style="
                    font-size: 12px;
                    font-family: poppinssemibold;
                    color: #fff;
                  "
                >
                  <v-icon>mdi-download-outline</v-icon> Excel</span
                >
              </a>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-5 v-if="purchase.length > 0">
      <v-flex xs12 sm11 style="font-family: poppinsregular">
        <v-card>
          <v-layout wrap>
            <v-flex xs12 py-5>
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 18px;
                  color: #1c1c1c;
                "
              >
                Summary
              </span>
            </v-flex>
            <v-layout wrap justify-space-between px-8 pb-5>
              <v-flex text-left>
                <v-layout wrap>
                  <v-flex xs12>
                    <span style="color: #7e8f85; font-family: poppinsregular"
                      >Total Amount
                    </span>
                  </v-flex>
                  <v-flex xs12 v-if="purchase[0].totalAmountSum">
                    <span style="color: #000000; font-family: poppinsmedium"
                      >₹ {{ purchase[0].totalAmountSum }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex text-center>
                <v-layout wrap>
                  <v-flex xs12>
                    <span style="color: #7e8f85; font-family: poppinsregular"
                      >Total Delivery Charges
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="color: #000000; font-family: poppinsmedium"
                      >{{ purchase[0].deliveryChargeSum }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex text-center>
                <v-layout wrap>
                  <v-flex xs12>
                    <span style="color: #7e8f85; font-family: poppinsregular"
                      >Total Discount
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="color: #000000; font-family: poppinsmedium"
                      >{{ purchase[0].discountSum }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex text-right>
                <v-layout wrap justify-end>
                  <v-flex xs12>
                    <span style="color: #7e8f85; font-family: poppinsregular"
                      >Total Orders
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="color: #000000; font-family: poppinsmedium">{{
                      purchase[0].count
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4 v-for="(item, i) in orders" :key="i">
      <!-- <v-flex xs12 sm11 text-left pb-4 pl-2>
          <span
            style="font-family: opensansbold; font-size: 20px; color: #808080"
          >
            {{ formatDate(item.create_date) }}
          </span>
        </v-flex> -->
      <v-layout wrap justify-center>
        <v-flex xs12 sm11>
          <v-card>
            <v-layout wrap justify-star py-3 px-5>
              <v-flex xs12 py-2>
                <v-layout wrap justify-space-between>
                  <v-flex text-left>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="color: #7e8f85; font-family: poppinsregular"
                          >Order Date
                        </span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="color: #000000; font-family: poppinsmedium"
                          >{{ formatDate(item.create_date) }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex text-center>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="color: #7e8f85; font-family: poppinsregular"
                          >Order Number
                        </span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="color: #000000; font-family: poppinsmedium"
                          >{{ item.orderId }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex text-right>
                    <v-layout wrap justify-end>
                      <v-flex xs12>
                        <span
                          style="color: #7e8f85; font-family: poppinsregular"
                          >Order Status
                        </span>
                      </v-flex>
                      <v-flex xs12>
                        <span
                          style="color: #30b868; font-family: poppinsmedium"
                          >{{ item.orderStatus }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 py-4>
                <v-divider></v-divider>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
                lg12
                xl12
                py-2
                text-start
                v-for="(transaction, i) in item.orderDetails"
                :key="i"
              >
                <v-layout wrap>
                  <v-flex xs1 v-if="transaction.productId">
                    <v-img
                      :src="mediaUURL + transaction.productId.photos[0]"
                      height="100px"
                      contain
                      :alt="transaction.name"
                    >
                      <template v-slot:placeholder>
                        <ImageLoader />
                      </template>
                    </v-img>
                  </v-flex>
                  <v-flex xs9>
                    <v-layout wrap justify-center fill-height>
                      <v-flex xs12 sm12 md12 align-self-center pl-2 text-left>
                        <v-layout
                          wrap
                          justify-start
                          style="line-height: 16px"
                          fill-height
                          v-if="transaction.productId"
                        >
                          <v-flex xs12>
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                                color: #191919;
                              "
                            >
                              {{ transaction.productId.productname }}
                              <!-- <span v-if="list.name.length > 25"> ... </span> -->
                            </span>
                          </v-flex>
                          <v-flex xs12 pt-3 v-if="transaction.itemId">
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >Size
                              <span style="font-family: poppinsregular"
                                >: {{ transaction.itemId.size }}</span
                              >
                            </span>
                          </v-flex>
                          <v-flex xs12 pt-1>
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >OTY
                              <span style="font-family: poppinsregular"
                                >: {{ transaction.quantity }}
                              </span>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 py-2>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12 sm6 text-left>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-btn
                        color="#30B868"
                        dark
                        :to="'/orderDetails?id=' + item._id"
                        ><span
                          style="
                            font-family: opensanssemibold;
                            font-size: 13px;
                            color: white;
                          "
                          >VIEW ORDER</span
                        ></v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              <v-flex xs12 sm6 text-right>
                <v-layout wrap justify-end>
                  <v-flex xs12 pb-3 v-if="item.isPromoCodeApplied">
                    <span
                      style="
                        color: #3d3b3b;
                        font-family: poppinsregular;
                        font-size: 14px;
                      "
                      >Promo Code Applied :</span
                    >
                    <span style="color: #3d3b3b; font-family: poppinsregular"
                      >₹ {{ item.couponDiscount }}</span
                    >
                  </v-flex>
                  <v-flex xs12 pb-4>
                    <span
                      style="
                        color: #3d3b3b;
                        font-family: poppinsregular;
                        font-size: 14px;
                      "
                      >Total Amount :
                    </span>
                    <span style="color: #383838; font-family: poppinsmedium"
                      >₹ {{ item.subTotal }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="green"
            circle
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-5 v-if="orders.length < 1">
      <v-flex xs12 text-center>
        <span
          style="font-family: poppinsregular; font-size: 25px; color: #000000"
        >
          Oops! No Data Found
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 10,
      menu1: false,
      menu2: false,
      fromDate: new Date(),
      toDate: new Date(),
      seller: "All",
      day: "last 7 days",
      days: [
        "last 7 days",
        "last 30 days",
        "last 3 months",
        "last 6 months",
        "last year",
        "Custom",
      ],
      orderStatus: "",
      orders: [],
      purchase: [],
      shop: [],
      sellerId: "",
      items: [
        { name: "All", value: "" },
        { name: "Pending", value: "Pending" },
        { name: "Active", value: "Active" },
        { name: "Accepted", value: "Accepted" },
        { name: "Shipped", value: "Shipped" },
        { name: "Delivered", value: "Delivered" },
      ],
      headers: [
        { text: "Shop Name", value: "_id.shopName", width: "140px" },
        { text: "Phone", value: "_id.contactNumber", width: "80px" },
        { text: "Total Amount", value: "totalAmountSum", width: "80px" },
        { text: "Delivery Charges", value: "deliveryChargeSum", width: "80px" },
        { text: "Order Count", value: "count", width: "80px" },
        // { text: "Actions", value: "_id", width: "50px" },
      ],
    };
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  watch: {
    currentPage() {
      this.getData();
    },
    fromDate() {
      // this.getData();
    },
    toDate() {
      // this.getData();
    },
    orderStatus() {
      this.getData();
    },
  },
  beforeMount() {
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate() - 7);
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
      this.getData();
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
  },
  methods: {
    dateFilter(day) {
      var to = new Date();
      var from = new Date(to.getTime());
      if (day == "last 7 days") {
        from = from.setDate(from.getDate() - 7);
        from = new Date(from);
      } else if (day == "last 30 days") {
        from = from.setDate(from.getDate() - 30);
        from = new Date(from);
      } else if (day == "last 3 months") {
        from = from.setMonth(from.getMonth() - 3);
        from = new Date(from);
      } else if (day == "last 6 months") {
        from = from.setMonth(from.getMonth() - 6);
        from = new Date(from);
      } else if (day == "last year") {
        from = from.setMonth(from.getMonth() - 12);
        from = new Date(from);
      }
      // else if (day == "Lifetime") {
      //   from = "";
      //   to = "";
      // }
      this.fromDate = from.toISOString().substr(0, 10);
      this.toDate = to.toISOString().substr(0, 10);
      this.getData();
    },
    checkDate() {
      // let ndate = new Date().toISOString().substr(0, 10);
      // if (this.fromDate == ndate) {

      var m = new Date(this.fromDate);
      // var j = new Date(this.fromDate);
      var n = new Date(this.toDate);
      if (m <= n && n >= m) {
        const tomorrow = new Date(this.toDate);
        tomorrow.setDate(tomorrow.getDate() - 15);
        // this.fromDate = tomorrow.toISOString().substr(0, 10);
        // }
        // n.setDate(j.getDate() + 13);
        if (n - m < 1296000000) {
          // console.log("GH");
          // console.log("M=", m);
          // console.log("N=", n);
          // console.log("res", n - m);
          n.setDate(n.getDate());
          // console.log("Ms=", m.toISOString().substr(0, 10));
          // console.log("Ns=", n.toISOString().substr(0, 10));
          this.toDate = n.toISOString().substr(0, 10);
          // console.log("GH", this.fromDate);
          this.getData();
        } else {
          // console.log("els casssse");
          // console.log(m);
          // console.log(n);
          m.setMonth(n.getMonth());
          m.setFullYear(n.getFullYear());
          m.setDate(n.getDate() - 15);
          // n.setMonth(m.getMonth());
          // n.setFullYear(m.getFullYear());
          // n.setDate(m.getDate() + 15);
          // console.log("fin", n);
          // console.log("Msss=", m.toISOString().substr(0, 10));
          // console.log("Nsss=", n.toISOString().substr(0, 10));
          // console.log("els case", m.getMonth());
          this.fromDate = m.toISOString().substr(0, 10);
          this.toDate = n.toISOString().substr(0, 10);
          this.getData();
        }
      } else {
        n.setMonth(m.getMonth());
        n.setFullYear(m.getFullYear());
        n.setDate(m.getDate() + 15);
        this.toDate = n.toISOString().substr(0, 10);
        this.getData();
      }
    },
    checkDate1() {
      var m = new Date(this.fromDate);
      var n = new Date(this.toDate);
      if (m <= n && n >= m) {
        if (n - m < 1296000000) {
          // console.log("GH");
          // console.log("M=", m);
          // console.log("N=", n);
          // console.log("res", n - m);
          n.setDate(n.getDate());
          // console.log("Ms=", m.toISOString().substr(0, 10));
          // console.log("Ns=", n.toISOString().substr(0, 10));
          this.toDate = n.toISOString().substr(0, 10);
          // console.log("GH", this.fromDate);
          this.getData();
        } else {
          // console.log("els casssse");
          // console.log(m);
          // console.log(n);
          n.setMonth(m.getMonth());
          n.setFullYear(m.getFullYear());
          n.setDate(m.getDate() + 7);
          // m.setMonth(n.getMonth());
          // m.setFullYear(n.getFullYear());
          // m.setDate(n.getDate() - 15);
          // console.log("fin", m);
          // console.log("els case", n.getMonth());
          this.fromDate = m.toISOString().substr(0, 10);
          this.toDate = n.toISOString().substr(0, 10);
          this.getData();
        }
      } else {
        m.setMonth(n.getMonth());
        m.setFullYear(n.getFullYear());
        m.setDate(n.getDate() - 7);
        this.fromDate = m.toISOString().substr(0, 10);

        this.getData();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/order/summary",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          orderStatus: this.orderStatus,
          sellerId: this.appUser.sellerId._id,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.orders = response.data.data;
            this.purchase = response.data.summary;
            this.pages = Math.ceil(response.data.totalLength / this.limit);
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var defDate = new Date(item);
      defDate = defDate.toString().slice(0, 15);
      var today = new Date();
      today = today.toString().slice(0, 15);

      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = yesterday.toString().slice(0, 15);

      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(0, 4) + day + " " + dt.slice(4, 7) + " " + year;

      if (today == defDate) {
        return "Today";
      } else if (yesterday == defDate) {
        return "Yesterday";
      } else {
        return strTime;
      }
    },
  },
};
</script>